.contentBox {
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  padding: 20px; /* Adjust padding as needed */
  border-radius: 10px; /* Rounded corners for the box */
  min-width: 20vw; /* Adjust width as needed */
  max-width: 40vw; /* Maximum width of the box */
  text-align: center; /* Center align content inside the box */
  backdrop-filter: blur(10px); /* Blur effect for the background */
  -webkit-backdrop-filter: blur(10px); /* For Safari */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* Optional: Add shadow */
}

.mainContainer {
  position: relative; /* Ensure positioning works */
  z-index: 1; /* Ensure content is above overlay */
}

.App {
  position: relative; /* Ensure positioning works */
  text-align: center; /* Center align content inside App */
  overflow: hidden; /* Hide overflow if needed */
  /* Add other background styles here if necessary */
}

.titleContainer {
  display: flex;
  flex-direction: column;
  font-size: 64px;
  font-weight: bolder;
  align-items: center;
  justify-content: center;
}

.resultContainer,
.historyItem {
  flex-direction: row;
  display: flex;
  width: 400px;
  align-items: center;
  justify-content: space-between;
}

.historyContainer {
  flex-direction: column;
  display: flex;
  height: 200px;
  align-items: center;
  flex-grow: 5;
  justify-content: flex-start;
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 260px;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.inputContainer > .errorLabel {
  color: red;
  font-size: 12px;
}

.inputBox {
  height: 48px;
  width: 400px;
  font-size: large;
  border-radius: 8px;
  border: 1px solid grey;
  padding-left: 8px;
}

.upload-form-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
}

.daily-count-container {
  margin-right: 10px;
}

.daily-count-container label {
  font-weight: bold;
}

.daily-count-container input {
  width: 80px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.file-upload-form {
  display: inline-block;
  vertical-align: top;
  margin-right: 1%;
  margin-left: 2%;
}

.highlight-active {
  background-color: #d4edda !important;/* Light green background */
}
.highlight-cancelled {
  background-color: #fff3cd !important; /* Light orange background */
}
.highlight-font-red {
  color: #f15c37 !important;
}
.highlight-font-orange {
  color: #f1bb08 !important;
}

/* animated gradient */
body {
  margin:0;
}
.wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    background: linear-gradient(45deg,#F17C58, #E94584, #24AADB , #27DBB1,#FFDC18, #FF3706);
    background-size: 600% 100%;
    animation: gradient 10s linear infinite;
    animation-direction: alternate;
}
@keyframes gradient {
    0% {background-position: 0%}
    100% {background-position: 100%}
}